//datele si partialele pt testul 16 lipsesc!!
//manually fucking review all fucking questions and fucking write an offset for 1-1 matching ffs
export const titles_map = [{
    "title": "Chapter 1: Colonial foundations",
    "chapter_index": "Chapter 1",
    "root_name": "pdf-chapter1.pdf",
    "start_page": "1",
    "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter1`,
    "length": "31",
    "no_tests": "9"
},
    {
        "title": "Chapter 2: The American Revolution",
        "chapter_index": "Chapter 2",
        "root_name": "pdf-chapter2.pdf",
        "start_page": "33",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter2`,
        "length": "39",
        "no_tests": "14"
    },
    {
        "title": "Chapter 3: The Story of Our Constitution",
        "chapter_index": "Chapter 3",
        "root_name": "pdf-chapter3.pdf",
        "start_page": "73",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter3`,
        "length": "39",
        "no_tests": "17"
    },
    {
        "title": "Chapter 4: The First Presidents of the New Republic",
        "chapter_index": "Chapter 4",
        "root_name": "pdf-chapter4.pdf",
        "start_page": "113",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter4`,
        "length": "29",
        "no_tests": "17"
    },
    {
        "title": "Chapter 5: The Age of Jackson ",
        "chapter_index": "Chapter 5",
        "root_name": "pdf-chapter5.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter5`,
        "length": "29",
        "no_tests": "15"
    },
    {
        "title": "Chapter 6: The Civil War",
        "chapter_index": "Chapter 6",
        "root_name": "pdf-chapter6.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter6`,
        "length": "29",
        "no_tests": "19"
    },
    {
        "title": "Chapter 7: The Reconstruction Era",
        "chapter_index": "Chapter 7",
        "root_name": "pdf-chapter7.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter7`,
        "length": "29",
        "no_tests": "12"
    },
    {
        "title": "Chapter 8: The Rise of Industrial America",
        "chapter_index": "Chapter 8",
        "root_name": "pdf-chapter8.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter8`,
        "length": "29",
        "no_tests": "11"
    },
    {
        "title": "Chapter 9: The Last Frontier",
        "chapter_index": "Chapter 9",
        "root_name": "pdf-chapter9.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter9`,
        "length": "29",
        "no_tests": "13"
    },
    {
        "title": "Chapter 10: The Progressive Era",
        "chapter_index": "Chapter 10",
        "root_name": "pdf-chapter10.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter10`,
        "length": "29",
        "no_tests": "12"
    },
    {
        "title": "Chapter 11: American Imperialism",
        "chapter_index": "Chapter 11",
        "root_name": "pdf-chapter11.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter11`,
        "length": "29",
        "no_tests": "14"
    },
    {
        "title": "Chapter 12: The United States in World War I",
        "chapter_index": "Chapter 12",
        "root_name": "pdf-chapter12.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter12`,
        "length": "29",
        "no_tests": "8"
    },
    {
        "title": "Chapter 13: The Roaring Twenties",
        "chapter_index": "Chapter 13",
        "root_name": "pdf-chapter13.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter13`,
        "length": "29",
        "no_tests": "12"
    },
    {
        "title": "Chapter 14: The Great Depression and the New Deal",
        "chapter_index": "Chapter 14",
        "root_name": "pdf-chapter14.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter14`,
        "length": "29",
        "no_tests": "12"
    },
    {
        "title": "Chapter 15: America in World War II",
        "chapter_index": "Chapter 15",
        "root_name": "pdf-chapter15.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter15`,
        "length": "29",
        "no_tests": "14"
    },
    {
        "title": "Chapter 16: The Truman and Eisenhower Years: Cold War, Prosperity, and Civil Rights",
        "chapter_index": "Chapter 16",
        "root_name": "pdf-chapter16.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter16`,
        "length": "29",
        "no_tests": "13"
    },
    {
        "title": "Chapter 17: The Kennedy and Johnson Years: Cuba, Civil Rights, the Great Society and Vietnam",
        "chapter_index": "Chapter 17",
        "root_name": "pdf-chapter17.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter17`,
        "length": "29",
        "no_tests": "13"
    },
    {
        "title": "Chapter 18: The Presidency in Crisis: Presidents Nixon, Ford and Carter",
        "chapter_index": "Chapter 18",
        "root_name": "pdf-chapter18.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter18`,
        "length": "29",
        "no_tests": "13"
    },
    {
        "title": "Chapter 19: America in Recent Times: Presidents Reagan, George H.W. Bush, Clinton, George W. Bush and Obama",
        "chapter_index": "Chapter 19",
        "root_name": "pdf-chapter19.pdf",
        "start_page": "143",
        "url": `${process.env.REACT_APP_DW_URL}/chapters/chapter19`,
        "length": "29",
        "no_tests": "13"
    },
];

export const test_status_map = {
    "1": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
    ],
    "2": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
        {"14": {"no_answers": "4", "type": "sq"}},
    ],
    "3": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
        {"14": {"no_answers": "4", "type": "sq"}},
        {"15": {"no_answers": "4", "type": "sq"}},
        {"16": {"no_answers": "4", "type": "sq"}},
        {"17": {"no_answers": "4", "type": "sq"}},
    ],
    "4": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq", "multipart": true}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
        {"14": {"no_answers": "4", "type": "sq"}},
        {"15": {"no_answers": "4", "type": "sq"}},
        {"16": {"no_answers": "4", "type": "sq"}},
        {"17": {"no_answers": "4", "type": "sq"}},
    ],
    "5": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq","adjustment_threshold":-1}}, // trebuie -1
        {"4": {"no_answers": "4", "type": "sq", "multipart": true}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq","adjustment_threshold":-1}}, // trebuie -1
        {"8": {"no_answers": "4", "type": "sq","adjustment_threshold":-1}}, // trebuie -1
        {"9": {"no_answers": "4", "type": "sq","adjustment_threshold":-1}}, // trebuie -1
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
        {"14": {"no_answers": "4", "type": "sq", "multipart": true}},
        {"15": {"no_answers": "4", "type": "sq"}},
    ],
    "6": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq","adjustment_threshold":-1}},//trb -1
        {"14": {"no_answers": "4", "type": "sq","adjustment_threshold":-1}}, // trb -1
        {"15": {"no_answers": "4", "type": "sq"}},
        {"16": {"no_answers": "4", "type": "sq", "multipart": true}},
        {"17": {"no_answers": "6", "type": "mcq"}},
        {"18": {"no_answers": "6", "type": "mcq","adjustment_threshold":-2}}, //trb -2
        {"19": {"no_answers": "4", "type": "sq","adjustment_threshold":-2}}, //trb -2
    ],
    "7": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
    ],
    "8": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq", "multipart": true}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "7", "type": "mcq","adjustment_threshold":-2}},//trb -2
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq","adjustment_threshold":-3}}, // trb -3
    ],
    "9": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "6", "type": "mcq","adjustment_threshold":-2}},// trb -2
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "6", "type": "mcq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
    ],
    "10": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
    ],
    "11": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "6", "type": "mcq","adjustment_threshold":-4}}, // trb -4
        {"3": {"no_answers": "4", "type": "sq", "multipart": true,"adjustment_threshold":-2}}, // trb -2
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
        {"14": {"no_answers": "6", "type": "mcq","adjustment_threshold":-4}}, // trb -4
    ],
    "12": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
    ],

    "13": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "6", "type": "mcq","adjustment_threshold":-4}}, // trb -4
        {"6": {"no_answers": "4", "type": "sq","adjustment_threshold":-2}}, //trb -2
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
    ],
    "14": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
    ],
    "15": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq","adjustment_threshold":-1}}, // trb -1
        {"9": {"no_answers": "4", "type": "sq","adjustment_threshold":-1}}, //trb -1
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
        {"14": {"no_answers": "4", "type": "sq"}},
    ],
    "16": [   
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
        {"14": {"no_answers": "4", "type": "sq"}},
        {"15": {"no_answers": "4", "type": "sq"}},
        {"16": {"no_answers": "4", "type": "sq"}},
    ],
    "17": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
    ],
    "18": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq"}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "6", "type": "mcq","adjustment_threshold":-2}},//trb -2
        {"11": {"no_answers": "4", "type": "sq", "multipart": true}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
    ],
    "19": [
        {"1": {"no_answers": "4", "type": "sq"}},
        {"2": {"no_answers": "4", "type": "sq"}},
        {"3": {"no_answers": "4", "type": "sq"}},
        {"4": {"no_answers": "4", "type": "sq"}},
        {"5": {"no_answers": "4", "type": "sq", "multipart": true}},
        {"6": {"no_answers": "4", "type": "sq"}},
        {"7": {"no_answers": "4", "type": "sq"}},
        {"8": {"no_answers": "4", "type": "sq"}},
        {"9": {"no_answers": "4", "type": "sq"}},
        {"10": {"no_answers": "4", "type": "sq"}},
        {"11": {"no_answers": "4", "type": "sq"}},
        {"12": {"no_answers": "4", "type": "sq"}},
        {"13": {"no_answers": "4", "type": "sq"}},
    ],
}