import React, {useContext, useEffect, useState} from 'react';
import {ListGroup, Container, Tabs, Tab, Accordion, Card} from 'react-bootstrap';
import Header from "../Header/Header";
import Footer from '../Footer/Footer';
import Course from '../Course/Course';
import {UserContext} from "../../App";


const Home = () => {

    const pdfCount = 19;
    const pdfNamesArray = Array.from({length: pdfCount}, (_, i) => `pdf-chapter${i + 1}.pdf`);

    const {loggedInUser} = useContext(UserContext);
    const {classes, testsStatus} = useContext(UserContext);
    const [courses] = useState(pdfNamesArray);
    const classesMap = {};
    classes.forEach((classItem) => {
        classesMap[classItem.id] = classItem.title;
    });
    useEffect(() => {

        if (sessionStorage.getItem('refreshHome') === 'true') {
            sessionStorage.removeItem('refreshHome');
            window.location.reload();
        }
    }, []);
    return (
        <Container>
            <Header/>
            {testsStatus && testsStatus.length >= 1 && (
                <Tabs defaultActiveKey="0" id="uncontrolled-tab-example">
                    {testsStatus.map((classTestStatus, index) => {
                        // Use the lookup map to find the corresponding title
                        const title = classesMap[classTestStatus.class_src_id];
                        return (
                            <Tab eventKey={index} title={title}>
                                <ListGroup className="my-5">
                                    {loggedInUser.role === 'tutor' &&
                                        <ListGroup.Item>

                                            <h3> Teacher materials </h3>
                                            <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <a href={`${process.env.REACT_APP_DW_URL}/tg/ta`}> Answer
                                                                Key to A Nation Conceived in Liberty </a><br/>
                                                            <a href={`${process.env.REACT_APP_DW_URL}/tg/tg0-0`}> Introduction </a><br/>
                                                            <a href={`${process.env.REACT_APP_DW_URL}/tg/tg0-1`}> Strategies
                                                                for Using A Nation Conceived in Liberty </a><br/>
                                                            <a href={`${process.env.REACT_APP_DW_URL}/tg/tg0-2`}> Primary
                                                                Sources </a><br/>
                                                            <a href={`${process.env.REACT_APP_DW_URL}/tg/tg0-3`}> Marking
                                                                Up Your Text</a><br/>
                                                            <a href={`${process.env.REACT_APP_DW_URL}/tg/tg19-1`}> Additional Materials</a><br/>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </ListGroup.Item>}
                                    {
                                        courses.map((title, index) => (
                                            <ListGroup.Item key={index}>
                                                <Course title={title}
                                                        testEnabled={classTestStatus.test_status_map[(index + 1).toString()]}/>
                                            </ListGroup.Item>
                                        ))
                                    }
                                </ListGroup>
                            </Tab>
                        );
                    })}
                </Tabs>
            )}
            <Footer/>
        </Container>
    );
};

export default Home;
