import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {UserContext} from '../../App';
import {signInWithEmailAndPassword} from './LoginManager';
import {decode} from 'jsonwebtoken';
import rocket from '../../image/rocket.svg';
import loginBtn from '../../image/class_link.png';
import './Login.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Login = () => {
    const {setLoggedInUser} = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const {from} = location.state || {from: {pathname: '/'}};
    const {register, handleSubmit} = useForm();
    const [user, setUser] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // Check if the user is already logged in
        const storedLoggedInUser = sessionStorage.getItem('loggedInUser');
        if (storedLoggedInUser) {
            setUser(JSON.parse(storedLoggedInUser));
        }
    }, []);

    useEffect(() => {
        if (user) {
            setLoggedInUser(user);
            sessionStorage.setItem('refreshHome', 'true');
            history.replace(from);
        }
    }, [user, from, history, setLoggedInUser]);

    const onSubmitEmail = (data) => {
        const {email, password} = data;

        signInWithEmailAndPassword(email, password)
            .then((response) => {
                if (response) {
                    if (response.status === 200 || response.status === 302) {
                        const token = response.data.tokens.access;
                        const decodedToken = decode(token);
                        const userRole = decodedToken.role;

                        const updatedUser = {
                            isSignedIn: true,
                            role: userRole,
                        };

                        setUser(updatedUser);
                        sessionStorage.setItem('loggedInUser', JSON.stringify(updatedUser));

                    }
                } else {
                    handleShow();
                }

            })
            .catch((error) => {
                console.log(error);
                handleShow();
            });
    };

    return (
        <div className={`containerr`}>
            <div className="forms-container">
                <div className="signin-signup">
                    <form onSubmit={handleSubmit(onSubmitEmail)} className="login-form sign-in-form">
                        <h2 className="title">Sign in</h2>
                        <div className="input-field">
                            <i className="fas fa-user"></i>
                            <input
                                name="email"
                                type="email"
                                ref={register({required: true, pattern: /\S+@\S+\.\S+/})}
                                placeholder="Email"
                                required/>
                        </div>
                        <div className="input-field">
                            <i className="fas fa-lock"></i>
                            <input
                                name="password"
                                type="password"
                                ref={register({required: true})}
                                placeholder="Password"
                                required/>
                        </div>
                        <button type="submit" className="btnn solid">Login</button>
                    </form>
                </div>
            </div>

            <div className="panels-container">
                <div className="panel left-panel">
                    <div className="content">
                        <p className='login-p'></p>
                        <h2>Single Sign-On (SSO)</h2>
                        <a href="https://launchpad.classlink.com/oauth2/v2/auth?scope=openid&redirect_uri=https%3A%2F%2Fapi.georgiaushistory.com%2Fapi%2Fv1%2Fredirect&client_id=c1712307036284b0ae9a77fb2388f81ad6eb26af27b6&response_type=code">
	    		     <img
                                alt="Log in with Classlink"
                                className="d-inline-block align-top small-btn"
                                src={loginBtn}>
                            </img>
                        </a>
                    </div>
                    <img src={rocket} className="image" alt=""/>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>Wrong username or password</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Login;
