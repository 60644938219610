import React, {useState, useContext} from 'react';
import {Accordion, Card, Button} from 'react-bootstrap';
import {UserContext} from '../../App';
import {titles_map} from "../utils/titles_map";
import {rcList} from "../utils/rc_map";
import {tgList} from "../utils/tg_map";
import {presList} from "../utils/pres_map";

const Course = ({title, testEnabled}) => {
    const [open, setOpen] = useState(false);
    const {loggedInUser} = useContext(UserContext);
    const handleToggle = () => setOpen(!open);


    let displayObjectChapterIndex = -1;
    const displayChapter = titles_map.find((item, index) => {
        if (item.root_name === title) {
            displayObjectChapterIndex = index;
            return true;
        }
        return false;
    });

    const displayRevisionCard = rcList[displayObjectChapterIndex];
    //adding the offset because there are the introduction materials
    const displayTestGateway = tgList[displayObjectChapterIndex + 5];
    return (
        <>
            <h3>🏛️ {displayChapter.title}</h3>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} varianut="link" eventKey="0" onClick={handleToggle}>
                            {open ? '▲' : '▼'} <span>{displayChapter.chapter_index} resources </span>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <a href={displayChapter.url}>📘 {displayChapter.title}</a><br/>
                            <a href={displayRevisionCard.url}>📓 {displayRevisionCard.title}</a><br/>
                            {(displayObjectChapterIndex + 1 === 18 || displayObjectChapterIndex + 1 === 19) &&
                                <a href={presList[(displayObjectChapterIndex + 1)-18].url}>📄 Presentation
                                    for {displayChapter.chapter_index}</a>}<br/>
                            {testEnabled && <a href={`/chapterTest/${displayObjectChapterIndex + 1}`}>📝 Test
                                for {displayChapter.chapter_index}</a>}<br/>
                            {loggedInUser.role === 'tutor' &&
                                <>
                                    <a href={displayTestGateway.url}>👩‍🏫 {displayTestGateway.title}</a><br/>
                                    <a href={`/testResults/${displayObjectChapterIndex + 1}`}>✔️ Test Results</a><br/>
                                    <a href={`/testStatistics/${displayObjectChapterIndex + 1}`}>📊 Test
                                        Statistics</a><br/>
                                </>
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};

export default Course;