import React, { useContext, useState, useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { UserContext } from '../../App';
import { logout, validateSpecialAction } from '../Login/LoginManager';

const SpecialRoute = ({ component: Component, ...rest }) => {
    const [isActionValid, setIsActionValid] = useState(null);
    const { loggedInUser, isLoading } = useContext(UserContext);
    const location = useLocation();

    useEffect(() => {
        const checkActionValidity = async () => {
            const isValid = await handleSpecialAction();
            setIsActionValid(isValid);
        };

        if (loggedInUser && Object.keys(loggedInUser).length > 0) {
            checkActionValidity();
        }
    }, [loggedInUser]);

    const handleSpecialAction = async () => {
        const response = await validateSpecialAction();
        if (response && response.status === 200) {
            return true;
        } else {
            logout();
            return false;
        }
    };

    if (isLoading) {
        return <div></div>;
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if (loggedInUser && Object.keys(loggedInUser).length > 0) {
                    if (isActionValid === null) {
                        return <div></div>;
                    } else if (isActionValid) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
                    }
                } else {
                    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
                }
            }}
        />
    );
};

export default SpecialRoute;
