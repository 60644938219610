import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { UserContext } from '../../App';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedInUser, isLoading } = useContext(UserContext);
  const location = useLocation();

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedInUser && Object.keys(loggedInUser).length > 0 ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;