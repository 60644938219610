export const tgList= [
    {
        "title": "Answer Key to A Nation Conceived in Liberty",
        "chapter_index":"Answer Key to A Nation Conceived in Liberty",
        "root_name": "ta.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/ta`,
        "length": "4"
    },
    {
    "title": "Introduction",
    "chapter_index":"Introduction",
    "root_name": "tg0-0.pdf",
    "start_page": "1",
    "url":`${process.env.REACT_APP_DW_URL}/tg/tg0-0`,
    "length": "5"
},
    {
        "title": "Strategies for Using A Nation Conceived in Liberty",
        "chapter_index":"Strategies for Using A Nation Conceived in Liberty",
        "root_name": "tg0-1.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg0-1`,
        "length": "16"
    },
    {
        "title": "Primary Sources",
        "chapter_index":"Primary Sources",
        "root_name": "tg0-2.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg0-2`,
        "length": "6"
    },
    {
        "title": "Marking Up Your Text",
        "chapter_index":"Marking Up Your Text",
        "root_name": "tg0-3.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg0-3`,
        "length": "2"
    },
    {
        "title": "Chapter 1: Colonial Foundations",
        "chapter_index":"Chapter 1",
        "root_name": "tg1.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg1`,
        "length": "33"
    },
    {
        "title": "Chapter 2 | The American Revolution ",
        "chapter_index":"Chapter 2",
        "root_name": "tg2.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg2`,
        "length": "40"
    },
    {
        "title": "Chapter 3: The Story of Our Constitution",
        "chapter_index":"Chapter 3",
        "root_name": "tg3.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg3`,
        "length": "39"
    },
    {
        "title": "Chapter 4: The First Presidents of the New Republic",
        "chapter_index":"Chapter 4",
        "root_name": "tg4.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg4`,
        "length": "33"
    },
    {
        "title": "Chapter 5: The Age of Jackson ",
        "chapter_index":"Chapter 5",
        "root_name": "tg5.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg5`,
        "length": "33"
    },

    {
        "title": "Chapter 6: The Civil War",
        "chapter_index":"Chapter 6",
        "root_name": "tg6.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg6`,
        "length": "31"
    },
    {
        "title": "Chapter 7: The Reconstruction Era",
        "chapter_index":"Chapter 7",
        "root_name": "tg7.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg7`,
        "length": "21"
    },
    {
        "title": "Chapter 8: The Rise of Industrial America",
        "chapter_index":"Chapter 8",
        "root_name": "tg8.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg8`,
        "length": "26"
    },
    {
        "title": "Chapter 9: The Last Frontier",
        "chapter_index":"Chapter 9",
        "root_name": "tg9.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg9`,
        "length": "22"
    },
    {
        "title": "Chapter 10: The Progressive Era",
        "chapter_index":"Chapter 10",
        "root_name": "tg10.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg10`,
        "length": "24"
    },
    {
        "title": "Chapter 11: American Imperialism",
        "chapter_index":"Chapter 11",
        "root_name": "tg11.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg11`,
        "length": "22"
    },
    {
        "title": "Chapter 12: The United States in World War I",
        "chapter_index":"Chapter 12",
        "root_name": "tg12.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg12`,
        "length": "22"
    },
    {
        "title": "Chapter 13: The Roaring Twenties",
        "chapter_index":"Chapter 13",
        "root_name": "tg13.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg13`,
        "length": "20"
    },
    {
        "title": "Chapter 14: The Great Depression and the New Deal",
        "chapter_index":"Chapter 14",
        "root_name": "tg14.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg14`,
        "length": "28"
    },
    {
        "title": "Chapter 15: America in World War II",
        "chapter_index":"Chapter 15",
        "root_name": "tg15.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg15`,
        "length": "27"
    },
    {
        "title": "Chapter 16: The Truman and Eisenhower Years: Cold War, Prosperity, and Civil Rights",
        "chapter_index":"Chapter 16",
        "root_name": "tg16.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg16`,
        "length": "29"
    },
    {
        "title": "Chapter 17: The Kennedy and Johnson Years: Cuba, Civil Rights, the Great Society and Vietnam",
        "chapter_index":"Chapter 17",
        "root_name": "tg17.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg17`,
        "length": "26"
    },
    {
        "title": "Chapter 18: The Presidency in Crisis: Presidents Nixon, Ford and Carter",
        "chapter_index":"Chapter 18",
        "root_name": "tg18.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg18`,
        "length": "25"
    },
    {
        "title": "Chapter 19: America in Recent Times: Presidents Reagan, George H.W. Bush, Clinton, George W. Bush and Obama",
        "chapter_index":"Chapter 19",
        "root_name": "tg19.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg19`,
        "length": "27"
    },
    {
        "title": "Additional Materials",
        "chapter_index":"Additional Materials",
        "root_name": "tg19-1.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/tg/tg19-1`,
        "length": "24"
    },
];
