import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../image/logo_r.svg';
import BurgerMenu from '../Navbar/Navbar';

const Header = () => {

    return (
        <Container className="mb-5 mt-2">
            <Navbar className="shadow-none d-flex justify-content-between" expand="lg">
                <Navbar.Brand as={Link} to="/">
                    <img
                        className="d-inline-block align-top"
                    src={logo} alt="First Choice Educational Publishing Logo"></img>
                    <h1
                        style={{ fontSize: "1.8rem", fontWeight: "bold" }}>
                    </h1>
                </Navbar.Brand>
                <BurgerMenu />
            </Navbar>
        </Container>
    );
};

export default Header;
