import React from 'react';
import { Tabs, Tab, Card, Row, Col, Button, Container } from 'react-bootstrap';
import Header from "../Header/Header";
import { useHistory } from "react-router-dom";

const TestReport = (props) => {
    const history = useHistory();
    const { userSubmissionAttempts, testQA, firstName, givenName } = props.location.state;

    const getAnswerLetter = (index) => {
        const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
        return letters[parseInt(index)] || '-';
    };

    return (
        <Container>
            <Header />

            <div className="buttonContainer">
                <Button variant="secondary" onClick={() => history.goBack()}>Go Back</Button>
            </div>

            <h2>{`${firstName} ${givenName}'s Test Report`}</h2>
            <Tabs defaultActiveKey="1">
                {Object.entries(userSubmissionAttempts).map(([attemptNumber, attempt]) => (
                    <Tab eventKey={attemptNumber} title={`Attempt ${attemptNumber}`}>
                        <Row>
                            {Object.entries(attempt).map(([questionIndex, answersPicked]) => {
                                const questionAndAnswers = testQA.find(qa => qa.question_index === parseInt(questionIndex));
                                return (
                                    <Col sm={6} md={4} lg={3}>
                                        <Card style={{ width: '18rem', marginBottom: '10px' }}>
                                            <Card.Body>
                                                <Card.Title>{`Question ${questionIndex} `}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted">Correct
                                                    Answer</Card.Subtitle>
                                                <Card.Text style={{ minHeight: '3rem' }}>
                                                    {
                                                        questionAndAnswers ? questionAndAnswers.answers.map(answer =>getAnswerLetter(parseInt(answer) - 1)).join(", ") : ""
                                                    }
                                                </Card.Text>
                                                <Card.Subtitle className="mb-2 text-muted">Answer Picked</Card.Subtitle>
                                                <Card.Text>{
                                                    answersPicked.map(answer => getAnswerLetter(parseInt(answer) - 1)).join(", ")
                                                }
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Tab>
                ))}
            </Tabs>
        </Container>
    );
}

export default TestReport;
